<template>
    <div class="team team__style--2 move-up wow" data-wow-delay="0.10s">
        <div class="thumb">
            <img :src="teamMember.image" :alt="teamMember.name">
            <div class="overlay"></div>
            <div class="team-info">
                <div class="info px-2">
                    <h5>{{ teamMember.name }}</h5>
                    <span>{{ teamMember.position }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['teamMember']
    };
</script>
